import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"



import headerStyles from '../styles/header.module.scss'

const navSlit = () => {

}

const Header = ({ siteTitle }) => (
  <div className={headerStyles.container}>
    <nav className={headerStyles.container}>

      <div className={headerStyles.content}>
        <div className={headerStyles.titlediv}>
          <Link
            to="/"
            className={headerStyles.title}
          >
            {siteTitle}
          </Link>

          <ul className={headerStyles.navlinks}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/product">Products</Link></li>
            <li><Link to="/location">Location</Link></li>
          </ul>
        </div>

        <div className={headerStyles.burger}>
          <div classNmame="line1"></div>
          <div classNmame="line2"></div>
          <div classNmame="line3"></div>
        </div>
      </div>
    </nav>

  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
