import PropTypes from "prop-types"
import React from "react"

import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import naverBlogLogo from '../images/blog_logo.jpg'


const Footer = () => (
  <section id="footer">

    <div className="icon">
      <a href="https://www.facebook.com/treatsbyeunice/" target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
      <a href="https://www.instagram.com/eunicetreats/" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
       <a href="https://www.youtube.com/channel/UCHjPkDkp_lt4n6FJB4e7aNA" target="_blank" rel="noopener noreferrer"><YouTubeIcon /></a>
    </div>
    <ul className="copyright">
      <li>Copyright &copy; Treats by Eunice. All rights reserved.</li><br/>
      <li className="createdby" style={{color: 'gray'}}>Created by: peppermintjin</li>
    </ul>
  </section>
)

export default Footer